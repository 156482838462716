import React from "react"
import DefaultPageLayout from "../components/DefaultPageLayout";

import SEO from "../components/seo"
import { CleanTextRoot, TextMargins, BlueChip } from "../components/Elements"
import CenteredPageLayout from "./CenteredPageLayout";
import BCNY from '../images/BCNY.svg';
import styled from 'styled-components';

const Logo = styled.img`
  filter: invert(1);
  opacity: 0.2;
`;

const Header = styled.h1`
  font-family: sans-serif;
  font-weight: 200;
  opacity: 0.2;
`;

const ComingSoonPage = () => (
  <CenteredPageLayout>
      <TextMargins>
        <Logo src={BCNY} />
        <Header>Coming Soon</Header>
      </TextMargins>
  </CenteredPageLayout>
);
export default ComingSoonPage;
