/**
 * Default Layout Component. Used
 * by all pages aside from Jobs
 */
 import React from "react"
 import styled, { createGlobalStyle } from "styled-components";
 
 
 const BodyStyle = createGlobalStyle`
   html, body {
     margin: 0;
     height: 100%;
     font-family: 'Nunito', 'Helvetica Neue', -apple-system, BlinkMacSystemFont, sans-serif;
   }
 `

 const Container = styled.div`
   min-height: 100vh;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   margin: auto;
   padding: 5vw;
   box-sizing: border-box;
   text-align: center;
 `;
 
 const CenteredPageLayout = ({ children }) => {
   return (
     <>
       <BodyStyle />
       <Container>
            {children}
       </Container>
     </>
   )
 }
 
 export default CenteredPageLayout
 